import React from "react";
import CreditCard from "../../components/creditcard";

const NairaCard = () => {
  
  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center p-8">
      <CreditCard />
    </div>
  );
};

export default NairaCard;
