export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo (Congo-Brazzaville)",
  "Congo (Democratic Republic)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const industries = [
  "Accommodation & Hospitality",
  "Accounting",
  "Advertising & Marketing",
  "Aerospace & Defense",
  "Agriculture, Forestry, Fishing & Hunting",
  "Arts, Entertainment & Design",
  "Automotive",
  "Banking",
  "Betting, Casinos & Lottery",
  "Biotechnology",
  "Business Support Services",
  "Charity Organizations",
  "Chemicals & Petrochemicals",
  "Clothing, Fashion & Textiles",
  "Commercial Printing Services",
  "Communications & Networking",
  "Computer Hardware & Software",
  "Construction & Architecture",
  "Consulting",
  "Consumer Goods & Services",
  "Corporate Advisory & Financial Services",
  "Courier, Postal, Freight & Land-based Logistics",
  "Educational Services",
  "Electronic Equipment & Parts",
  "Employment Services",
  "Energy & Utilities",
  "Environmental Services",
  "Financial Technology (FinTech)",
  "Food Retail & Distribution",
  "Gaming",
  "Government & Public Administration",
  "Green Energy & Renewable Resources",
  "Healthcare & Social Assistance",
  "Household Appliances, Tools & Hardware",
  "Industrial Machinery, Gas & Chemicals",
  "Information Technology (IT) Services & Consulting",
  "Insurance",
  "Interior Design & Furnishing",
  "Internet & Software",
  "Investment Banking & Brokerage Services",
  "Legal Services",
  "Manufacturing",
  "Mining",
  "Nonprofit & Philanthropy",
  "Office Equipment",
  "Oil Related Services & Equipment",
  "Online Retail & E-Commerce",
  "Personal Services",
  "Pharmaceutical Services",
  "Phones & Handheld Devices",
  "Professional & Business Education",
  "Professional, Scientific & Technical Services",
  "Public Safety Services",
  "Publishing & Content Creation",
  "Real Estate",
  "Rental & Leasing",
  "Research & Development",
  "Restaurants & Food Services",
  "Retail",
  "Security & Surveillance",
  "Software Development",
  "Specialist Engineering, Infrastructure & Contractors",
  "Sports & Fitness",
  "Telecommunications",
  "Tourism, Travel & Leisure",
  "Transportation",
  "Warehousing & Storage",
  "Waste Management & Recycling",
  "Water & Wastewater Treatment",
  "Web Development & Design",
  "Wholesale Trade",
  "Other",
];

export const RegistrationType = [
  "Business Name - Sole Proprietorship",
  "Business Name - Partnership",
  "Private Company Limited By Shares",
  "Private Unlimited Company",
  "Public Company Limited By Shares",
  "Public Unlimited Company",
  "Private Company Limited By Guarantee",
  "Public Company Limited By Guarantee",
  "Incorporated Trustee",
  "Limited Partnership",
  "Limited Liability Partnership",
];
